export async function subscribeUser(email, fields, tags = []) {
  // Updated endpoint to match the Netlify Functions URL
  const endpoint = '/.netlify/functions/subscribe';

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, ...fields, tags }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Subscription failed');
    }

    return await response.json();
  } catch (error) {
    return { result: 'error', msg: error.message };
  }
}
