import React, { useState } from 'react';
import cn from 'classnames';
import { subscribeUser } from '../../utils/subscribeUser';
import {
  Wrapper, HtmlContent,
  Buttons,
} from '../common';
import { convertColorToTheme, decodeEntities } from '../../utils/helpers';
import './NewsletterSignup.scss';

const NewsletterSignup = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items,
  } = data;
  const {
    theme,
    title,
    body,
    successMessageTitle,
    successMessageBody,
    successMessageButtonLabel,
    successMessageButtonLink,
  } = primary;

  const mailchimpTags = items.map(item => item.mailchimpTag);

  const themeClass = convertColorToTheme(theme.toLowerCase());

  // State variables for the subscribe form
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  // Handle the form submission using async/await
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    setSubmitSuccess(false);
    setSubmitError(false);

    const fields = {
      FNAME: firstName,
      LNAME: lastName,
    };

    const result = await subscribeUser(email, fields, mailchimpTags);

    if (result.result === 'success') {
      setSubmitSuccess(true);
    } else {
      setErrorMessage(decodeEntities(result.msg));
      setSubmitError(true);
    }
    setSubmitting(false);
  };

  // Button text based on the form state
  const signUpMessage = submitting ? 'Submitting...' : 'Sign up';

  // Determine if the error message indicates that the user is already subscribed
  const alreadySubscribed = errorMessage && (
    errorMessage.toLowerCase().includes('already subscribed')
    || errorMessage.toLowerCase().includes('too many requests')
  );

  const buttonClass = cn(`button styled-button styled-button-${themeClass}`);

  return (
    <section id="newsletter" className={`newsletter-signup theme-${themeClass}`}>
      <Wrapper isBound>
        {submitSuccess ? (
          // If the submission is successful, replace the entire container with the success message.
          <div className="container success-message">
            <div className="text-container">
              <h2 className="title text-h2">{successMessageTitle.text}</h2>
              <HtmlContent className="body-content" content={successMessageBody.html} />
              <Buttons
                theme={themeClass}
                styled
                buttons={[{
                  buttonLabel: { text: successMessageButtonLabel.text },
                  buttonLink: { url: successMessageButtonLink.url },
                }]}
              />
            </div>
          </div>
        ) : (
          // Otherwise, display the title, body, and subscribe form.
          <div className="container">
            <div className="text-container">
              <h2 className="title text-h2">{title.text}</h2>
              <HtmlContent className="body-content" content={body.html} />
            </div>
            <div className="form-container">
              <form
                className={`subscribe-form ${submitError ? 'has-error' : ''}`}
                name="subscribe"
                onSubmit={handleSubmit}
              >
                <label
                  className={`field ${firstName !== '' ? 'has-value' : ''}`}
                  htmlFor="first-name"
                >
                  <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    placeholder="First name"
                    onChange={e => setFirstName(e.target.value)}
                    required
                  />
                </label>
                <label
                  className={`field ${lastName !== '' ? 'has-value' : ''}`}
                  htmlFor="last-name"
                >
                  <input
                    type="text"
                    id="last-name"
                    name="last-name"
                    placeholder="Last name"
                    onChange={e => setLastName(e.target.value)}
                    required
                  />
                </label>
                <label
                  htmlFor="subscribe-email"
                  className={`field ${email !== '' ? 'has-value' : ''}`}
                >
                  <input
                    type="email"
                    id="subscribe-email"
                    name="subscribe-email"
                    placeholder="Email address"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    required
                    disabled={submitting}
                    readOnly={submitting}
                  />
                </label>
                <button
                  className={cn('submit-button', buttonClass)}
                  type="submit"
                  disabled={submitting}
                >
                  <span>{signUpMessage}</span>
                </button>
                {submitError && errorMessage && (
                  <div className="error-message">
                    <span>
                      {alreadySubscribed
                        ? "Oops! Looks like you're already subscribed to our mailing list."
                        : 'Uh oh! Something went wrong!'}
                    </span>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </Wrapper>
    </section>
  );
};

export default NewsletterSignup;
