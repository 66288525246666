import React, { Component } from 'react';
import { subscribeUser } from '../../utils/subscribeUser';
import { decodeEntities } from '../../utils/helpers';
import './SubscribeBlock.scss';

export default class SubscribeForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    errorMessage: null,
    submitting: false,
    submitSuccess: false,
    submitError: false,
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      firstName,
      lastName,
      email,
      submitting,
    } = this.state;
    if (submitting) return false;
    const fields = {
      FNAME: firstName,
      LNAME: lastName,
    };
    this.setState({
      submitting: true,
      submitSuccess: false,
      submitError: false,
    });

    const tags = ['website'];
    const result = await subscribeUser(email, fields, tags);

    if (result.result === 'success') {
      this.setState({
        submitSuccess: true,
        submitting: false,
      });
    } else {
      this.setState({
        errorMessage: decodeEntities(result.msg),
        submitError: true,
        submitting: false,
      });
    }
  }

  handleFirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };

  handleLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  }

  isAlreadySubscribed = () => {
    const { errorMessage } = this.state;
    return errorMessage.indexOf('already subscribed') || errorMessage.indexOf('too many requests');
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      submitting,
      submitSuccess,
      submitError,
    } = this.state;
    let signUpMessage = 'Sign up';
    if (submitting) signUpMessage = 'Submitting...';
    if (submitSuccess) signUpMessage = 'Success';
    return (
      <div className="subscribe-form-block">
        <div className={`form-container ${submitSuccess ? 'submit-success' : ''}`}>
          <form
            className={`subscribe-form ${submitError ? 'has-error' : ''}`}
            name="subscribe"
            onSubmit={event => this.handleSubmit(event)}
          >
            <label
              className={`field half-width ${firstName !== '' ? 'has-value' : ''}`}
              htmlFor="first-name"
              aria-label="First name"
            >
              <input
                type="text"
                id="first-name"
                name="first-name"
                placeholder="First name"
                onChange={event => this.handleFirstNameChange(event)}
                required
              />
            </label>
            <label
              className={`field half-width ${lastName !== '' ? 'has-value' : ''}`}
              htmlFor="last-name"
              aria-label="Last name"
            >
              <input
                type="text"
                id="last-name"
                name="last-name"
                placeholder="Last name"
                onChange={event => this.handleLastNameChange(event)}
                required
              />
            </label>
            <label htmlFor="subscribe-email" className={`field full-width ${email !== '' ? 'has-value' : ''}`} aria-label="Email">
              <input
                type="email"
                id="subscribe-email"
                disabled={submitting}
                name="subscribe-email"
                placeholder="Email address"
                onChange={event => this.handleEmailChange(event)}
                value={email}
                required
                readOnly={submitting || submitSuccess}
              />
            </label>
            <button
              className="submit-button styled-button styled-button-white"
              type="submit"
              disabled={submitting || submitSuccess}
            >
              <span>{signUpMessage}</span>
              {submitSuccess && (
                <div className="success-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 9">
                    <path fill="none" fillRule="evenodd" strokeWidth="1.5" d="M1 4.1888l3.762 3.762L11.7127 1" />
                  </svg>
                </div>
              )}
            </button>
            <div className="error-message">
              <span>{this.isAlreadySubscribed ? 'Oops! Looks like you\'re already subscribed to our mailing list.' : `Uh oh! Something went wrong!`}</span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
